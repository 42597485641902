.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
  border: 0px dotted #000; 
  padding-left:150px;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid rgb(156, 138, 138);
  /*color: #2b2eff;*/
  cursor: pointer;
}

.paginationBttns a:hover {
  color: rgb(160, 32, 32);
  background-color: #d1d1d4;
}

.paginationActive a {
  color: white;
  background-color: #a3a3a7;
}

.paginationDisabled a {
  color: rgb(199, 196, 196);
  background-color: rgb(245, 243, 243);
}

@media (max-width: 560px) {
  .paginationBttns,
  .paginationBttns a{
    margin: 5px;
    padding: 10px;
    font-size: smaller;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}